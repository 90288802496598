<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    persistent
    transition="dialog-bottom-transition"
  >
  <template v-slot:activator="{ on }">
      <v-btn
        v-on="on"
        x-small
        color="color3"
        text
        class="ma-0 pa-0"
      >Score board display</v-btn>
    </template>
    <v-card :dark="dark" class="pa-0" tile>
      <v-responsive :aspect-ratio="16/9">
        <v-card-text fill-height class="pa-0">
          <v-container fluid class="pa-0">
            <v-row dense>
              <v-col v-for="(d, i) in displayDuals" :key="`${d.id}-${i}`">
                <dual-card
                  :dual="d"
                  :dark="dark"
                  :tournament="tournament"
                  :displayOnly="true"
                  :cols="displayDuals.length === 1 ? null : null"
                ></dual-card>
              </v-col>
            </v-row>
            <v-row dense v-if="false">
              <v-col v-for="(m, i) in liveMatches" :key="`${m.id}-${i}`" cols="12">
                <match-card
                  :match="m"
                  :dark="dark"
                ></match-card>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-responsive>
      <v-btn
        color="grey"
        text icon absolute right top
        small
        @click.stop="dialog = false"
      >
        <v-icon>fas fa-times</v-icon>
      </v-btn>
      <v-card-actions class="justify-end">
        <v-btn
          color="grey"
          text icon absolute right bottom
          @click.stop="dark = !dark"
        >
          <v-icon>fa{{dark ? 'r' : 's'}} fa-sun</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import MatchCard from '@/components/Tournament/Match/NoDetailsMatchCard'
import DualCard from '@/components/Tournament/Duals/DualWrapper'
import { firstBy } from 'thenby'

export default {
  data () {
    return {
      dialog: false,
      dark: false,
      matchCount: 1
    }
  },
  computed: {
    ...mapGetters(['tournament']),
    grid () {
      return [
        { maxCount: 5, grid: [5, 1] }
      ]
    },
    allDuals () {
      return this.tournament.duals
    },
    displayDuals () {
      return this.liveDuals && this.liveDuals.length > 0 ? this.liveDuals : [this.nextDual]
    },
    liveDuals () {
      return this.allDuals.filter(f => f.status === 'Started')
    },
    nextDual () {
      let x = [...this.incompleteDuals]
      if (x.length === 0) return []
      const noUnix = x.find(f => !f.unix)
      if (noUnix) return []
      x = x.sort(firstBy('unix'))
      return x[0]
    },
    incompleteDuals () {
      return this.allDuals.filter(f => !f.status)
    },
    allMatches () {
      return this.tournament.allMatches
    },
    liveMatches () {
      return this.tournament.allMatches.filter(f => f.status === 'Started')
    }
  },
  methods: {
    openFullscreen () {
      const elem = document.documentElement
      if (elem.requestFullscreen) {
        elem.requestFullscreen()
      } else if (elem.webkitRequestFullscreen) { /* Safari */
        elem.webkitRequestFullscreen()
      } else if (elem.msRequestFullscreen) { /* IE11 */
        elem.msRequestFullscreen()
      }
    },
    closeFullscreen () {
      if (document.exitFullscreen) {
        document.exitFullscreen()
      } else if (document.webkitExitFullscreen) { /* Safari */
        document.webkitExitFullscreen()
      } else if (document.msExitFullscreen) { /* IE11 */
        document.msExitFullscreen()
      }
    }
  },
  watch: {
    dialog: function (v) {
      if (v) {
        this.openFullscreen()
      } else {
        this.closeFullscreen()
      }
    }
  },
  components: {
    MatchCard,
    DualCard
  }
}
</script>
