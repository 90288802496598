<template>
  <dual-card
    :dual="dual"
    :pool="pool"
    :bracket="bracket"
    :round="round"
    :division="division"
    :displayOnly="displayOnly"
    :noTitle="true"
    :cols="cols"
  ></dual-card>
</template>

<script>
import DualCard from '@/components/Tournament/Duals/DualCard'
export default {
  props: ['dual', 'tournament', 'displayOnly', 'cols'],
  computed: {
    match () {
      return this.dual.matches[0]
    },
    pool () {
      return this.match.poolId ? this.tournament.pools.find(f => f.id === this.match.poolId) : null
    },
    bracket () {
      return this.match.bracketId ? this.tournament.brackets.find(f => f.id === this.match.bracketId) : null
    },
    round () {
      return this.pool ? this.tournament.rounds.find(r => {
        return r.pools.find(p => p.id === this.pool.id)
      }) : this.bracket ? this.tournament.rounds.find(r => {
        return r.brackets.find(p => p.id === this.bracket.id)
      }) : null
    },
    division () {
      return this.round && this.tournament.divisions.find(d => {
        return d.rounds.find(f => f.id === this.round.id)
      })
    }
  },
  components: {
    DualCard
  }
}
</script>
